<template>
  <PopUp :show="modal" @hideModal="hideModal">
    <div class="collection-order">
      <header class="collection-order__header">
        <h3 class="collection-order__header_title">Reorder Collection</h3>
        <div class="collection-order__header_actions">
          <BaseButton
            class="button--sm button--green button--uppercase"
            text="Save Changes"
            :loading="loading"
            :disabled="!changed"
            @click="$emit('reorder', orderedItems)"
          ></BaseButton>
        </div>
      </header>
      <div class="collection-order__body_title">Artworks</div>
      <draggable
        class="collection-order__body"
        v-model="orderedItems"
        group="collection-order"
        @start="drag = true"
        @end="drag = false"
        @change="changeOrder"
      >
        <div v-for="element in orderedItems" :key="element.id" class="collection-order__body_item">
          <!-- <img :src="element.search_image" :alt="element.title" /> -->
          <p>
            {{ element.title }}
          </p>
          <div class="collection-order__body_item_container">
            <IconMoveDots class="collection-order__body_item_svg"></IconMoveDots>
          </div>
        </div>
      </draggable>
    </div>
  </PopUp>
</template>
<script>
import draggable from "vuedraggable";
import PopUp from "@/components/PopUp.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconMoveDots from "@/components/icons/IconMoveDots";
export default {
  props: {
    items: { type: Array, default: null },
    modal: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  components: {
    draggable,
    PopUp,
    BaseButton,
    IconMoveDots,
  },
  data() {
    return {
      orderedItems: [],
      changed: false,
    };
  },
  created() {
    //this.orderedItems = JSON.parse(JSON.stringify(this.items));
    this.sortable(this.items);
  },
  watch: {
    modal(val) {
      this.sortable(this.items);
    },
  },
  methods: {
    changeOrder() {
      let orderedItems = [];
      orderedItems = this.orderedItems.map((el, id) => {
        return (el = { ...el, order: id });
      });
      this.orderedItems = orderedItems;
      this.changed = true;
    },
    sortable(items) {
      let orderedItems = JSON.parse(JSON.stringify(items));
      orderedItems = orderedItems
        // .filter(el => {
        //   return el.artist_name === type;
        // })
        .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
      this.orderedItems = orderedItems;
    },
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$emit("reorder", this.orderedItems);
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
  },
};
</script>
<style lang="scss" scoped>
.collection-order {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      font-size: 3.35rem;
      font-weight: 600;
      line-height: 1.2;
      text-transform: capitalize;
      color: $black;
    }
    &_actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: $xs) {
        justify-content: flex-start;
        width: 100%;
      }
      .button {
        margin-left: 10px;
        @media only screen and (max-width: $xs) {
          margin: 20px 10px 0 0;
        }
      }
    }
  }
  &__body {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 20px;
    row-gap: 10px; // 20
    &_title {
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.2;
      text-transform: capitalize;
      color: $black;
      border-bottom: 1px solid $grey;
      padding: 5px 0;
      margin-bottom: 15px;
    }
    &_item {
      position: relative;
      display: flex;
      //flex-direction: column;
      align-items: center;
      justify-content: flex-start; // center
      //min-width: 22.8rem;
      //min-height: 22rem;
      border: 0.5px solid #bcbcbc;
      border-radius: 12px;
      padding: 12px 12px;
      background: $white;
      cursor: pointer;
      img {
        height: 100%;
        width: 100%;
        border-radius: 7px;
      }
      p {
        width: 100%;
        text-align: left;
        //margin-top: 5px;
        @include type($fs: 20px, $fw: 600); // 14
        color: $black;
      }
      &.sortable-chosen {
        background: rgba($color: $blueLight, $alpha: 0.5);
        color: $black;
      }
      &_container {
        //position: absolute;
        top: 22px;
        right: 22px;
        padding: 5px 5px;
        border-radius: 5px;
        height: 26px;
        width: 26px;
        background-color: $white;
      }
      &_svg {
        height: auto;
        width: auto;
      }
    }
  }
}
</style>
