<template>
  <div class="page">
    <div class="page__header">
      <h1 style="margin-top: 10px">Collection</h1>
    </div>
    <div class="page__header">
      <HorisontalTabsList :tabs="tabs" />
      <div class="page__header_right">
        <IconButton
          class="button--auto button--uppercase"
          text="reorder collection"
          @click="showOrderModal"
        >
          <IconReorder></IconReorder>
        </IconButton>
        <IconButton
          class="button--auto button--uppercase"
          text="new category"
          @click="modal = true"
        >
          <IconPlusBold />
        </IconButton>
      </div>
    </div>
    <Table
      :loading="loading"
      :items="items"
      :headerItems="headerItems"
      :tableData="tableData"
      :hasMorePages="hasMorePages"
      @sortTable="sortTable"
      @clickAction="clickAction"
      @loadMore="loadMore"
    />
    <Category
      v-if="modal"
      :modal="modal"
      :item="category"
      @hideModal="modal = false"
      @createCategory="createCategory"
      @updateCategory="updateCategory"
      @removeCategory="confirmeRemoveCategory"
    />
    <OrderModal
      v-if="orderModal"
      :modal="orderModal"
      :items="collection"
      :loading="loadingCollection"
      @reorder="reorderItems"
      @hideModal="orderModal = false"
    ></OrderModal>
  </div>
</template>
<script>
import HorisontalTabsList from "@/components/HorisontalTabsList.vue";
import Table from "@/components/Table.vue";
import Category from "./Category.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconPlusBold from "@/components/icons/IconPlusBold.vue";
import IconReorder from "@/components/icons/IconReorder.vue";
import OrderModal from "./OrderModal.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
export default {
  components: {
    HorisontalTabsList,
    Table,
    Category,
    IconButton,
    IconPlusBold,
    IconReorder,
    OrderModal,
  },
  data() {
    return {
      tabs: [
        {
          title: "Artwork",
          path: "/admin/collection/artworks",
          key: "artwork",
        },
        {
          title: "Creators",
          path: "/admin/collection/creators",
          key: "artists",
        },
        {
          title: "Categories",
          path: "/admin/collection/categories",
          key: "categories",
        },
        {
          title: "Viewing Rooms",
          path: "/admin/collection/viewing-rooms",
          key: "viewing-rooms",
        },
      ],
      modal: false,
      orderModal: false,
      tableData: {
        page: 1,
        sort: "title",
        order: "asc",
        status: "",
      },
    };
  },
  async created() {
    await this.apiGetCategories(this.tableData);
    await this.apiGetCategoryTypes();
    this.initialCategory();
  },
  computed: {
    ...mapGetters("categories", {
      items: "getCategories",
      headerItems: "getCategoriesHeaders",
      loading: "getLoading",
      hasMorePages: "getHasMorePages",
      category: "getCategory",
    }),
    ...mapGetters("artworks", {
      loadingCollection: "getLoading",
      collection: "getCollection",
    }),
  },
  watch: {
    modal(val) {
      if (!val) {
        this.initialCategory();
        // this.setError(null);
      }
    },
  },
  methods: {
    ...mapActions("general", ["apiDeleteUploadImage"]),
    ...mapActions("categories", [
      "apiGetCategories",
      "apiGetCategory",
      "apiPostCategory",
      "apiPutCategory",
      "apiDeleteCategory",
      "apiGetCategoryTypes",
    ]),
    ...mapActions("artworks", ["apiGetCollection", "apiPutCollectionOrder"]),
    ...mapMutations("categories", ["initialCategory"]),
    async sortTable({ sort, order }) {
      let data = { ...this.tableData, page: 1, sort, order };
      let success = await this.apiGetCategories(data);
      if (success) {
        this.tableData = data;
      }
    },
    async loadMore() {
      if (this.hasMorePages) {
        let data = { ...this.tableData, page: this.tableData.page + 1 };
        let success = await this.apiGetCategories(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    async clickAction({ key, id, item }) {
      switch (key) {
        case "edit": {
          let result = await this.apiGetCategory(id);
          if (result) {
            this.modal = true;
          }
          break;
        }
        case "delete": {
          let result = await this.confirmeRemoveCategory(item);
          if (result) {
            this.modal = true;
          }
          break;
        }
      }
    },
    async createCategory({ data, imageForRemove }) {
      let { result, error } = await this.apiPostCategory(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetCategories(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$swal({
          title: "Category successfully created",
          confirmButtonText: "Ok",
        });
      } else {
        this.$toasted.show(error, {
          duration: 4000,
          type: "error",
        });
      }
    },
    async updateCategory({ data, imageForRemove }) {
      let { result, error } = await this.apiPutCategory(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetCategories(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$swal({
          title: "Category successfully updated",
          confirmButtonText: "Ok",
        });
      } else {
        this.$toasted.show(error, {
          duration: 4000,
          type: "error",
        });
      }
    },
    confirmeRemoveCategory(item) {
      // don't delete uncategorized
      const categoryName = item.title ? item.title : item.name;
      if (categoryName === "uncategorized") {
        this.$toasted.show(`Can't delete category "uncategorized"!`, {
          duration: 2000,
          type: "error",
        });
      } else {
        this.$swal({
          title: `Delete ${categoryName}`,
          html: "<h5>Are you sure? <br/> This cannot be undone.",
          showCancelButton: true,
          showConfirmButton: false,
          showDenyButton: true,
          denyButtonText: "Delete",
          cancelButtonText: "Cancel",
        }).then(result => {
          if (result.value === false) {
            this.removeCategory(item.id);
          }
        });
      }
    },
    async removeCategory(id) {
      let result = await this.apiDeleteCategory(id);
      if (result.success) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetCategories(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$toasted.show(result.message, {
          duration: 2000,
          type: "error",
        });
      }
    },
    async showOrderModal() {
      await this.apiGetCollection();
      this.orderModal = true;
    },
    async reorderItems(items) {
      let result = await this.apiPutCollectionOrder(items);
      if (result) {
        this.orderModal = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    min-height: 7rem;
    &_right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .button {
        margin: 0 0 0 2rem;
      }
    }
  }
}
</style>

