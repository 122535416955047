<template>
  <PopUp :show="modal" @hideModal="hideModal">
    <ValidationObserver v-slot="{ invalid }" ref="observer" tag="div" class="category">
      <header class="category__header">
        <h3 class="category__header_title">{{ category.id ? "Edit" : "Add" }} Category</h3>
        <div class="category__header_actions">
          <BaseButton
            v-if="item"
            class="button--sm button--outline button--uppercase"
            text="Delete"
            @click="$emit('removeCategory', category)"
          ></BaseButton>
          <BaseButton
            class="button--sm button--green button--uppercase"
            text="Save Changes"
            :loading="changesLoading"
            :disabled="invalid || !category.image"
            @click="saveCategory"
          ></BaseButton>
        </div>
      </header>
      <section class="form">
        <h3 class="form__header">Required Information</h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Category Name"
              :required="true"
              :validateError="errors[0]"
              v-model="category.name"
              :element="category.name"
              :disabled="category.name === 'uncategorized'"
              @input="category.name = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Path"
              :required="true"
              :validateError="errors[0]"
              v-model="category.slug"
              :element="category.slug"
              :disabled="category.name === 'uncategorized'"
              @input="category.slug = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <div class="form__row_col">
            <BaseSelect
              placeholder="Type"
              v-model="category.category_type_id"
              :selected="category.category_type_id"
              :items="categoryTypes"
              @action="category.category_type_id = $event.key"
            ></BaseSelect>
          </div>
        </div>
        <div
          class="form__row form__row--label form__row--bordered form__row--justify-start pb-0"
          :class="{ 'form__row--error': imageValidateError }"
        >
          <span class="form__row_label"
            >Header Picture <span class="form__row_label_required">*</span>
          </span>
          <UploadImage
            class="uploader--lg"
            size="15"
            :image="category.image"
            :loadingImage="loadingImage"
            @upload="upload"
            @editImage="editImage"
            @removeImage="removeImage"
          ></UploadImage>
          <p class="form__row_error">{{ imageValidateError }}</p>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Artwork</h3>
        <div class="form__row">
          <MultipleAutocompleteInput
            id="autocomplete-artworks"
            :creating="false"
            :focusOpen="true"
            :image="true"
            :hasStatus="true"
            :loading="artworksLoading"
            :items="category.artworks"
            :matches="matchesArtworks"
            placeholder="Artwork Search"
            @selectItem="category.artworks = $event"
            @reorder="category.artworks = $event"
            @searchMatches="apiGetMatchedArtworks"
          ></MultipleAutocompleteInput>
        </div>
      </section>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import UploadImage from "@/components/UploadImage.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import MultipleAutocompleteInput from "@/components/inputs/MultipleAutocompleteInput.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  props: { item: { type: Object, default: null }, modal: { type: Boolean, default: false } },
  components: {
    PopUp,
    UploadImage,
    BaseButton,
    BaseInput,
    MultipleAutocompleteInput,
    BaseSelect,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      category: {
        name: "",
        slug: "",
        image: "",
        artworks: [],
        category_type_id: "",
      },
      imageValidateError: null,
      removedImages: [],
      changed: false,
      loadingImage: false,
    };
  },
  created() {
    if (this.item != null) {
      this.category = JSON.parse(JSON.stringify(this.item));
    }
  },
  computed: {
    ...mapGetters("categories", {
      changesLoading: "getChangesLoading",
      categoryTypes: "getCategoryTypes",
    }),
    ...mapGetters("artworks", {
      matchesArtworks: "getMatchedArtworks",
      artworksLoading: "getChangesLoading",
    }),
  },
  watch: {
    category: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (oldVal.name != "") {
            this.changed = true;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("general", ["apiPostUploadImage"]),
    ...mapActions("artworks", ["apiGetMatchedArtworks"]),
    async upload(file) {
      this.loadingImage = true;
      let image = await this.apiPostUploadImage(file);
      if (image) {
        this.category.image = image;
        this.imageValidateError = null;
      }
      this.loadingImage = false;
    },
    async editImage({ el, idx }) {
      this.loadingImage = true;
      this.removedImages.push(this.category.image);
      let image = await this.apiPostUploadImage(el);
      if (image) {
        this.category.image = image;
      }
      this.loadingImage = false;
    },
    async removeImage(idx) {
      this.loadingImage = true;
      this.imageValidateError = "This field is required";
      this.removedImages.push(this.category.image);
      this.category.image = "";
      this.loadingImage = false;
    },
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid && this.category.image
              ? this.saveCategory()
              : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
    saveCategory() {
      let data = {
        ...this.category,
        artworks: this.category.artworks.map(el => {
          return {
            id: el.id,
            order: el.order,
          };
        }),
      };
      if (this.category.id) {
        this.$emit("updateCategory", { data: data, imageForRemove: this.removedImages });
      } else {
        this.$emit("createCategory", { data: data, imageForRemove: this.removedImages });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.category {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      font-size: 3.35rem;
      font-weight: 600;
      line-height: 1.2;
      text-transform: capitalize;
    }
    &_actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: $xs) {
        justify-content: flex-start;
        width: 100%;
      }
      .button {
        margin-left: 10px;
        @media only screen and (max-width: $xs) {
          margin: 20px 10px 0 0;
        }
      }
    }
  }
}
</style>
